<template>
  <div id="app" v-if="initData" style="background-color: #ffffff;">
    <p><strong>initData:</strong></p>
    <pre class="full-width wrap-text">{{ initData }}</pre>
    <p><strong>token:</strong></p>
    <pre class="full-width wrap-text">{{ token }}</pre>
    <p><strong>config:</strong></p>
    <pre class="full-width">{{ config }}</pre>
    <p><strong>upgrades:</strong></p>
    <pre class="full-width">{{ upgrades }}</pre>
    <p><strong>referrals:</strong></p>
    <pre class="full-width">{{ referrals }}</pre>
    <p><strong>tasks:</strong></p>
    <pre class="full-width">{{ tasks }}</pre>

    <div v-if="config && config.tasks">
      <h3>Tasks</h3>
      <ul>
        <li v-for="task in config.tasks" :key="task.id">
          <span>{{ task.title }}</span>
          <button @click="handleTaskAction(task)" :disabled="task.loading">
            <span v-if="task.loading">Loading...</span>
            <span v-else>Go to Task</span>
          </button>
        </li>
      </ul>
    </div>
  </div>
  <div v-else>
    <p>404 Not Found</p>
  </div>
</template>

<style>
.full-width {
  width: 100%;
  box-sizing: border-box;
  margin: 0;
  padding: 10px;
  overflow-x: auto;
}

.wrap-text {
  white-space: pre-wrap; /* Preserves whitespace and wraps text */
  word-wrap: break-word; /* Breaks long words to prevent overflow */
  overflow-wrap: break-word; /* Alternative to word-wrap for better compatibility */
}

#app {
  text-align: center;
  margin-top: 50px;
}

pre {
  text-align: left;
  display: inline-block;
  background-color: #f5f5f5;
  padding: 10px;
}

button:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}
</style>

<script>
/* global Telegram */
import axios from 'axios';

const apiURL = "dev.capbit.io";

export default {
  name: 'App',
  data() {
    return {
      initData: '',
      initDataUnsafe: {},
      token: '',
      config: null,
      upgrades: null,
      referrals: null,
      tasks: null,
    };
  },
  created() {
    this.initializeApp();
  },
  methods: {
    async initializeApp() {
      if (window.Telegram && window.Telegram.WebApp) {
        this.initData = Telegram.WebApp.initData || '';
        this.initDataUnsafe = Telegram.WebApp.initDataUnsafe || {};

        if (!this.initData) {
          console.log('initData is empty, returning 404');
          return;
        }

        console.log('initData:', this.initData);
        
        await this.sendInitDataToServer(this.initData);
      } else {
        console.log('Application is not initialized');
      }
    },
    async sendInitDataToServer(data) {
      try {
        const response = await axios.post(`https://${apiURL}/auth/webapp-session`, {
          initData: data,
          invitedBy: 0,
        });
        this.token = response.data.accessToken;
        console.log('Token successfully received:', this.token);

        // Set the Authorization header for all future axios requests
        axios.defaults.headers.common['Authorization'] = `Bearer ${this.token}`;

        // Fetch data
        await this.fetchData();
      } catch (error) {
        console.error('Error sending data to the server:', error);
      }
    },
    async fetchData() {
      try {
        const endpoints = [
          { url: `/app/configuration`, prop: 'config' },
          { url: `/app/upgrades`, prop: 'upgrades' },
          { url: `/app/referrals`, prop: 'referrals' },
          { url: `/app/tasks`, prop: 'tasks' },
        ];

        const requests = endpoints.map(endpoint =>
          axios.get(`https://${apiURL}${endpoint.url}`)
        );

        const responses = await Promise.all(requests);

        responses.forEach((response, index) => {
          const property = endpoints[index].prop;
          this[property] = response.data;
          console.log(`${property} successfully loaded:`, response.data);
        });
      } catch (error) {
        console.error('Error loading data:', error);
      }
    },
    async handleTaskAction(task) {
      task.loading = true;
      try {
        // Redirect to the task's action URL
        window.location.href = task.action;

        // Send a POST request with the task ID
        const response = await axios.post(`https://${apiURL}/tasks`, { id: task.id });

        if (response.status === 200) {
          console.log(`Task ${task.id} action completed successfully`);
        }
      } catch (error) {
        if (error.response) {
          const status = error.response.status;
          if (status === 401) {
            console.error(`Unauthorized access for task ${task.id}`);
          } else if (status === 400) {
            console.error(`Bad request for task ${task.id}`);
          } else {
            console.error(`Error handling task ${task.id}:`, error);
          }
        } else {
          console.error(`Error handling task ${task.id}:`, error);
        }

        // Retry the request after 20 seconds
        setTimeout(() => this.handleTaskAction(task), 20000);
      } finally {
        task.loading = false;
      }
    },
  },
};
</script>